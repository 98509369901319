import Button from '@/components/Common/Buttons/Button/Button';
import PropertyAd from '@/components/Components/PropertyAd/PropertyAd';
import AgencyInfoBox from '@/views/SingleAgencyPage/AgencyInfoBox/AgencyInfoBox';
import Footer from "@/components/Footer/Footer";
import SortingDropdown from "@/components/Components/SortingDropdown/SortingDropdown";

export default {
    name: 'SingleAgencyPage',
    components: {
        SortingDropdown,
        Button,
        PropertyAd,
        AgencyInfoBox,
        Footer
    },
    data() {
        return {
            item: null,
            properties: [],
            filter: {},
            order: 'createdAt',
            propertySortingOptions: [
                {
                    title: 'Цена възходяща',
                    value: 'rentPrice.ASC',
                },
                {
                    title: 'Цена низходяща',
                    value: 'rentPrice.DESC',
                },
                {
                    title: 'Дата на добавяне',
                    value: 'createdAt',
                },

            ],
            offset: 0,
            limit: 4,
            isMoreResultsLoading: false,
            hasMoreResults: false,
        };
    },
    async mounted() {
        const id = this.$route.params.id;

        if (this.$route.name === 'Agency') {
            const agency = await this.$store.dispatch('agency/getAgency', id);
            this.setItem(agency.name, agency.createdAt, "Dsadas", agency.phoneCollection, agency.emailCollection, agency.logo);
            this.filter.agencyId = agency.id;
        } else {
            const user = await this.$store.dispatch('user/getUser', id);
            this.setItem(user.name, user.createdAt, "Dsadas", [user.phone], user.emailCollection, user.profilePicture);
            this.filter.userId = user.id;
        }

        this.updateProperties();
    },
    methods: {
        async setItem(name, date, description, phones, emails, profilePicture) {
            this.item = {
                name,
                date,
                description,
                phones,
                emails,
                profilePicture
            }
        },
        async updateProperties(append = false) {
            const result = await this.$store.dispatch('property/getProperties', {
                params: this.filter,
                offset: this.offset,
                limit: this.limit,
                order: this.order
            });
            if (append) {
                this.properties = [...this.properties, ...result];
            } else {
                this.properties = result;
            }
            this.hasMoreResults = result.length >= this.limit;
        },
        async handleSorting(order) {
            this.order = order;
            this.offset = 0;
            this.updateProperties(false);
        },
        async showMoreResults() {
            if (this.isMoreResultsLoading) return;
            this.isMoreResultsLoading = true;
            this.offset += this.limit;
            await this.updateProperties(true);
            this.isMoreResultsLoading = false;
        }
    },
};
